const state = {
	namespaced: true,
	loading: false,
	background: false,
}

const actions = {
	show({ commit }) {
		commit('show')
	},
	hide({ commit }) {
		commit('hide')
	},
	background({ commit }) {
		commit('background')
	},
}

const mutations = {
	show(state) {
		if (state.background === false) {
			state.loading = true
		}
	},
	hide(state) {
		state.loading = false
		state.background = false
	},
	background(state) {
		state.background = true
	},
	displayLoader(state) {
		state.loading = true
		state.background = true
	},
	hideLoader(state) {
		state.loading = false
		state.background = false
	},
}

export default {
	state,
	actions,
	mutations,
}
