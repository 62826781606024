import { get, put } from './httpservices'
import { store } from '../store/store'
import { router } from '../router/index'
import { userprofile } from './authservices'

export async function getcompany_id() {
	if (store.state.auth.user_data.company_id === '') {
		await userprofile()
		return store.state.auth.user_data.company_id
	} else {
		return store.state.auth.user_data.company_id
	}
}

export async function getShippingCharge(list_id) {
	let resp
	try {
		resp = await get('distributor/shipping-charge/' + list_id)
		if (resp.data.error) {
			// store.commit('errorMessage', resp.data.error)
			return false
		}
		return resp.data
	} catch (e) {
		// router.push('/500')
		return e.response
	}
}

export async function deliveries() {
	let company_id = await getcompany_id()
	if (company_id) {
		let resp
		try {
			resp = await get('distributor/deliveries/' + company_id)
			return resp.data
		} catch (e) {
			console.log(e)
			// router.push('/500')
			return e.response
		}
	}
}

export async function recentDeliveries() {
	store.commit('background')
	let company_id = await getcompany_id()
	if (company_id) {
		let resp
		try {
			resp = await get('distributor/recent/deliveries/' + company_id)
			if (!resp.data.dataset && !resp.data.data) {
				store.commit('errorMessage', '')
			}
			return resp.data
		} catch (e) {
			console.log(e)
			// router.push('/500')
			return e.response
		}
	}
}

export async function getDelivery(bid_id) {
	let resp
	try {
		resp = await get('distributor/delivery/' + bid_id)
		return resp.data
	} catch (e) {
		console.log(e)
		// router.push('/500')
		return e.response
	}
}

export async function delivered(bid_id, data) {
	let resp
	try {
		resp = await put('delivery/' + bid_id, data)
		return resp.data
	} catch (e) {
		console.log(e)
		// router.push('/500')
		return e.response
	}
}

export async function deliveryExport(id) {
	let resp
	try {
		resp = await get('delivery-export/' + id)
		return resp.data
	} catch (e) {
		console.log(e)
		// router.push('/500');
		return e.response
	}
}

export async function getDeliveryCalendar() {
	let company_id = await getcompany_id()
	if (company_id) {
		let resp
		try {
			resp = await get('distributor/deliveries/calendar/' + company_id)
			return resp.data
		} catch (e) {
			console.log(e)
			// router.push('/500')
			return e.response
		}
	}
}
