import { get, post } from './httpservices'
import { store } from '../store/store'
import { router } from '../router/index'
import { userprofile } from './authservices'

export async function getcompany_id() {
	if (store.state.auth.user_data.company_id === '') {
		await userprofile()
		return store.state.auth.user_data.company_id
	} else {
		return store.state.auth.user_data.company_id
	}
}

export async function lists() {
	let company_id = await getcompany_id()
	if (company_id) {
		let resp
		try {
			resp = await get('distributor/lists/' + company_id)
			return resp.data
		} catch (e) {
			console.log(e)
			// router.push('/500');
			return e.response
		}
	}
}

export async function recentLists() {
	store.commit('background')
	let company_id = await getcompany_id()
	if (company_id) {
		let resp
		try {
			resp = await get('distributor/recent/lists/' + company_id)
			if (!resp.data.dataset && !resp.data.data) {
				store.commit('errorMessage', '')
			}
			return resp.data
		} catch (e) {
			console.log(e)
			// router.push('/500');
			return e.response
		}
	}
}

export async function getList(list_id) {
	let resp
	try {
		resp = await get('distributor/list/' + list_id)
		return resp.data
	} catch (e) {
		console.log(e)
		// router.push('/500');
		return e.response
	}
}
