import Vue from 'vue'
import './plugins/axios'
import { router } from './router/index'
import { store } from './store/store'
import vuetify from './plugins/vuetify'
import VueTheMask from 'vue-the-mask'
import Cookies from 'js-cookie'
import firebase from 'firebase/app'

Vue.config.productionTip = false

import App from './App'

Vue.use(VueTheMask)

new Vue({
	el: '#app',
	router,
	store,
	vuetify,
	firebase,
	render: (h) => h(App),
})

router.beforeEach((to, from, next) => {
	if (to.name !== 'login' && to.name !== 'resetpassword' && to.name !== 'resetlink' && to.name !== 'register' && Cookies.get('access_token') == undefined) {
		store.dispatch('authLogout')
		next('/login')
	} else {
		next()
	}
})
