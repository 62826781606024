
const state = {
    delivery_object: []
}

const mutations = {
    deliveryObject: (state, dataset) => {
        state.delivery_object = [];
        dataset.forEach(item => {
            let delivery = {};

            delivery.delivery_id = item.delivery_id;
            delivery.status = item.status;
            delivery.bid_id = item.bid.bid_id;
            delivery.list_id = item.clist.list_id;
            delivery.category_id = item.clist.category_id;
            delivery.list_name = item.clist.list_name;
            delivery.contact_name = item.contractor.fullname;
            delivery.contact_phone = item.contractor.phone;
            delivery.list_lat = item.clist.latitude;
            delivery.list_long = item.clist.longitude;
            delivery.bid_lat = item.bid.latitude;
            delivery.bid_long = item.bid.longitude;
            delivery.check = item.status != 'IP';
            delivery.name = 'delivery' + item.delivery_id;
            delivery.date = item.bid.date_of_delivery;
            delivery.pickup = item.clist.pickup;
            delivery.url = item.clist.url;

            state.delivery_object.push(delivery);
        });
    }
}

export default {
    state,
    mutations,
}
