<template>
  <v-app style="background:#F7F9FC">
    <v-overlay
      :absolute="absolute"
      :opacity="opacity"
      :value="show"
      :z-index="zIndex"
      color="rgba(247, 249, 252, 0.46)"
    >
      <img :src="require('@/assets/loader.gif')" style="height:65px;" alt />
    </v-overlay>
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
  </v-app>
</template>

<script>
import { userprofile, buildIcons } from "./services/authservices";
import Cookies from "js-cookie";
import { Base_URL } from "./config/variable";
require("./scss/_variables.scss");
require("./scss/mediaquery.scss");
import "./plugins/components";
import "./services/firebase";
import "./plugins/vuefilter";

export default {
  data() {
    return {
      absolute: false,
      opacity: 0.2,
      zIndex: 9999
    };
  },

  computed: {
    show() {
      return this.$store.state.loader.loading === true ? true : false;
    }
  },

  methods: {
    hide() {
      this.$store.commit("show");
    }
  },

  mounted() {
    buildIcons();

    let current_route = this.$router.currentRoute.path;
    let register_path = ["/register", "/404", "/500"];

    if (!register_path.includes(current_route)) {
      userprofile();
    }
  }
};
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>