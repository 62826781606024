<template>
  <div class="r-layout">
    <v-row class="ma-0 flex-lg-row flex-md-column">
      <v-col cols="12" lg="2" md="12" class="side-menu">
        <img :src="$store.state.icons.icons['favicon-32x32']" alt />

        <v-row
          class="col-lg-12 col-md-6 col-12"
          style="padding-top: 48px; padding-left: 0"
          no-gutters
        >
          <p class="text-center my-heading" style="white-space: nowrap">
            Simplify the build.
          </p>
          <p class="simple-text">
            Digital construction management tools empowering productivity.
          </p>
        </v-row>

        <v-row class="registration-list" no-gutters>
          <div class="d-lg-block d-flex">
            <div
              :class="{
                active: this.$store.state.register.active_tab === 'password',
              }"
            >
              <v-row
                v-if="this.$store.state.registerstep.passwordcomplete"
                no-gutters
                class="menu-link-complete"
              >
                <div class="number-box text-center">
                  <img
                    :src="$store.state.icons.icons['Tick']"
                    style="height: 9px"
                    alt
                  />
                </div>

                <p class="link-text d-sm-flex d-none align-center">Password</p>
              </v-row>

              <v-row v-else no-gutters class="menu-link">
                <div class="number-box text-center">
                  <p class="menu-number">1</p>
                </div>

                <p class="link-text d-sm-flex align-center d-none" :class="{
                'd-flex': this.$store.state.register.active_tab === 'password',
              }">Password</p>
              </v-row>
            </div>

            <div
              :class="{
                active: this.$store.state.register.active_tab === 'account',
              }"
            >
              <v-row
                v-if="this.$store.state.registerstep.accountcomplete"
                no-gutters
                class="menu-link-complete"
              >
                <div class="number-box text-center">
                  <img
                    :src="$store.state.icons.icons['Tick']"
                    style="height: 9px"
                    alt
                  />
                </div>

                <p class="link-text d-sm-flex d-none align-center">Account information</p>
              </v-row>

              <v-row v-else no-gutters class="menu-link">
                <div class="number-box text-center">
                  <p class="menu-number">2</p>
                </div>

                <p class="link-text d-sm-flex d-none align-center" 
                :class="{
                'd-flex': this.$store.state.register.active_tab === 'account',
              }">Account information</p>
              </v-row>
            </div>

            <div
              :class="{
                active: this.$store.state.register.active_tab === 'product',
              }"
            >
              <v-row
                v-if="this.$store.state.registerstep.categorycomplete"
                no-gutters
                class="menu-link-complete"
              >
                <div class="number-box text-center">
                  <img
                    :src="$store.state.icons.icons['Tick']"
                    style="height: 9px"
                    alt
                  />
                </div>

                <p class="link-text d-sm-flex d-none align-center">Product categories</p>
              </v-row>

              <v-row v-else no-gutters class="menu-link">
                <div class="number-box text-center">
                  <p class="menu-number">3</p>
                </div>

                <p class="link-text d-sm-flex d-none align-center"
                :class="{
                'd-flex': this.$store.state.register.active_tab === 'product',
              }">Product categories</p>
              </v-row>
            </div>

            <div
              :class="{
                active: this.$store.state.register.active_tab === 'authorize',
              }"
            >
              <v-row
                v-if="this.$store.state.registerstep.authorizecomplete"
                no-gutters
                class="menu-link-complete"
              >
                <div class="number-box text-center">
                  <img
                    :src="$store.state.icons.icons['Tick']"
                    style="height: 9px"
                    alt
                  />
                </div>

                <p class="link-text d-sm-flex d-none align-center">Authorize users</p>
              </v-row>

              <v-row v-else no-gutters class="menu-link">
                <div class="number-box text-center">
                  <p class="menu-number">4</p>
                </div>

                <p class="link-text d-sm-flex d-none align-center"
                :class="{
                'd-flex': this.$store.state.register.active_tab === 'authorize',
              }">Authorize users</p>
              </v-row>
            </div>

            <div
              :class="{
                active: this.$store.state.register.active_tab === 'delivery',
              }"
            >
              <v-row
                v-if="this.$store.state.registerstep.deliverycomplete"
                no-gutters
                class="menu-link-complete"
              >
                <div class="number-box text-center">
                  <img
                    :src="$store.state.icons.icons['Tick']"
                    style="height: 9px"
                    alt
                  />
                </div>

                <p class="link-text d-flex align-center">Delivery zones</p>
              </v-row>

              <v-row v-else no-gutters class="menu-link">
                <div class="number-box text-center">
                  <p class="menu-number">5</p>
                </div>

                <p class="link-text d-sm-flex align-center d-none"
                :class="{
                'd-flex': this.$store.state.register.active_tab === 'delivery',
              }">Delivery zones</p>
              </v-row>
            </div>
          </div>
        </v-row>
      </v-col>

      <v-col
        cols
        style="padding: 40px; min-height: 100vh"
        class="side-content d-flex"
      >
        <transition name="fade" mode="out-in">
          <app-password
            v-if="this.$store.state.register.active_tab === 'password'"
          ></app-password>
          <app-account
            v-if="this.$store.state.register.active_tab === 'account'"
          ></app-account>
          <app-authorize
            v-if="this.$store.state.register.active_tab === 'authorize'"
          ></app-authorize>
          <app-product
            v-if="this.$store.state.register.active_tab === 'product'"
          ></app-product>
          <app-delivery
            v-if="this.$store.state.register.active_tab === 'delivery'"
          ></app-delivery>
        </transition>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { setUser } from "../../services/registerservice";
import Cookie from "js-cookie";
export default {
  components: {
    AppPassword: () => import("./Password"),
    AppAccount: () => import("./Account"),
    AppAuthorize: () => import("./Authorize"),
    AppProduct: () => import("./Product"),
    AppDelivery: () => import("./Delivery"),
  },
  watch: {
    $route(to, from) {
      if (this.$router.currentRoute.path === "/register") {
        if (this.$router.currentRoute.path != "/password") {
          this.$router.push({ path: "/password" });
        }
      }
    },
  },

  async created() {
    if (this.$store.state.register.register_user.user_id === "") {
      await setUser();

      let status = this.$store.state.register.register_user.status;

      if (status === "A") {
        this.$router.push({ path: "/login" });
      }
    }
  },
};
</script>

<style lang="scss">
.r-layout .col-2 {
  padding: 0px !important;
  // max-width:330px !important;
  min-width: 330px !important;
  background: #f0f4f9 !important;
  /*min-height: 100vh !important;*/
}
.registration-list {
  margin-top: 80px;
}
.r-layout .side-menu {
  padding: 40px !important;
  z-index: 5;
  border-right: 1px solid #dde2ee !important;
}

.r-layout .side-content {
  background: #f7f9fc;
}

.r-layout .simple-text {
  font-family: Inter-Regular;
  font-size: 13px;
  color: #7587a3;
  line-height: 18px;
}

.r-layout .my-heading {
  margin-bottom: 3px;
}

.r-layout .number-box {
  border: 1px solid #dbe1eb;
  border-radius: 3px;
  height: 32px;
  width: 32px;
  padding-top: 4px !important;
  margin-right: 12px;
}

.r-layout .menu-number {
  font-family: Inter-Regular;
  font-size: 14px;
  color: #b9c6d9;
}

.r-layout .link-text {
  font-family: Inter-Regular;
  font-size: 13px;
  color: #7587a3;
}

.r-layout .menu-link {
  margin-bottom: 16px;
  cursor: pointer;
  align-items: center;

  p {
    margin: 0px !important;
  }
}

.r-layout .menu-link-complete {
  margin-bottom: 16px;
  cursor: pointer;

  p {
    margin: 0px !important;
  }

  .number-box {
    background: #dae5f8;
    border-radius: 3px;
    padding-top: 2px !important;

    img {
      filter: brightness(0) saturate(100%) invert(27%) sepia(66%)
        saturate(3182%) hue-rotate(214deg) brightness(103%) contrast(101%);
    }
  }

  .link-text {
    font-family: Inter-Medium;
    color: #176aff;
  }
}

.active {
  .number-box {
    background: #216fff;
    border-radius: 3px;
  }

  .menu-number {
    font-family: Inter-SemiBold;
    font-size: 14px;
    color: #ffffff;
  }

  .link-text {
    font-family: Inter-Medium;
    color: #176aff;
  }
}

.R-Layout {
  padding: 40px;
}
@media only screen and (max-width: 1024px) {
  .link-text {
    width: 90px;
    padding-right: 12px;
  }
  .registration-list {
    margin-top: 20px;
  }
}
@media only screen and (max-width: 600px) {
  .menu-link{
    width: max-content;
  }
}
</style>