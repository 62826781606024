import { get, post, put } from './httpservices'
import { store } from '../store/store'
import Cookies from 'js-cookie'
import { AXIOS } from '../plugins/axios'
import { router } from '../router/index'

export async function userprofile() {
	let resp
	try {
		resp = await get('auth/user')
		store.dispatch('authUser', resp.data.data)
	} catch (e) {
		// Cookies.remove("access_token");
		// Cookies.remove("refresh_token");
		// Cookies.remove("fcm_id");
		store.dispatch('authLogout')

		if (router.currentRoute.fullPath !== '/login') {
			router.push({ path: '/login' })
		}
	}
}

export async function userlogout() {
	try {
		// Cookies.remove("access_token");
		// Cookies.remove("refresh_token");
		// Cookies.remove("fcm_id");
		await post('auth/logout', { fcm_id: Cookies.get('fcm_id') })
		store.dispatch('authLogout')
	} catch (e) {
		return e.response
	}
}

export async function userProfileUpdate(data) {
	let resp
	try {
		resp = await post('auth/profile-update', data)
		return resp.data.data
	} catch (e) {
		console.log(e)
		return e.response
		// router.push('/500')
	}
}

export async function passwordChange(data) {
	let resp
	try {
		resp = await post('auth/change-password', data)
		return resp.data
	} catch (e) {
		console.log(e)
		return e.response
		// router.push('/500')
	}
}

export async function userdata() {
	let resp
	try {
		resp = await get('auth/userdata')
		return resp.data
	} catch (e) {
		return e.response
	}
}

export async function preference(data) {
	let resp
	try {
		resp = await put('user-preference', data)
		return resp.data
	} catch (e) {
		return e.response
	}
}

export async function storeFCMId(fcm_id) {
	let resp
	try {
		resp = await post('auth/fcm-token', fcm_id)
		store.state.auth.fcm_id = resp.data.data.fcm_id
		return resp.data
	} catch (e) {
		return e.response
	}
}

import { Debug_Logs } from '../config/variable'
export function errorLogs(data) {
	if (Debug_Logs === 'true') {
		store.commit('background')
		post('logs', { data: data })
	}
}

export async function buildIcons() {
	return new Promise((resolve, reject) => {
		AXIOS.get('icon-url')
			.then(response => {
				store.commit('buildIcons', response.data)
				resolve(response)
			})
			.catch(error => {
				console.log(error)
				reject
			})
	})
}
