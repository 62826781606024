import firebase from 'firebase/app'
import { storeFCMId } from './authservices'
import Cookies from 'js-cookie'
import 'firebase/messaging'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import { router } from '../router/index'
import { store } from '../store/store'
import { getNotification } from '../services/homeservice'
import { getList } from '../services/listservice'
import { errorLogs } from '../services/authservices'
import { getDelivery } from '../services/deliveryservice'

var firebaseConfig = {
	apiKey: 'AIzaSyD3IbOkxziyhhHCTz6YubXaee_euTz0yhM',
	authDomain: 'thebuild-1574405407229.firebaseapp.com',
	databaseURL: 'https://thebuild-1574405407229.firebaseio.com',
	projectId: 'thebuild-1574405407229',
	storageBucket: 'thebuild-1574405407229.appspot.com',
	messagingSenderId: '18710456779',
	appId: '1:18710456779:web:cd024fe7d696d134516e4e',
	measurementId: 'G-VCNNH0QRWG',
}

// Initialize Firebase
firebase.initializeApp(firebaseConfig)

const messaging = firebase.messaging.isSupported() ? firebase.messaging() : ''

store.state.auth.not_supported = !firebase.messaging.isSupported()

if (firebase.messaging.isSupported()) {
	messaging.usePublicVapidKey('BOyM9ZSqWubDd1X7LnAA8zQNC1IEo-uve66K8ucf6xc6H_t2snvrMd8_GiGIehhCRgqYR0BBssLFLkXKZ89s5to')

	navigator.serviceWorker
		.register('firebase-messaging-sw.js')
		.then((registration) => {
			console.log(registration)
		})
		.catch((err) => {})
}

if (firebase.messaging.isSupported()) {
	messaging.onMessage(function (payload) {
		const notificationTitle = 'The Build'
		getData(payload.data)

		if (payload.data.code === 'BLC' || payload.data.code === 'BAB' || payload.data.code === 'BLAB' || payload.data.code === 'BBTSC') {
			getBackgroundUpdate(payload.data)
		} else {
			const notificationOptions = {
				body: payload.notification.body,
				icon: '/favicon-32x32.png',
				// requireInteraction: payload.data.requireInteraction,
				// tag: payload.data.tag
			}

			if (!('Notification' in window)) {
				console.log('This browser does not support system notifications')
			}
			// Let's check whether notification permissions have already been granted
			else {
				if (Notification.permission === 'granted') {
					// If it's okay let's create a notification
					try {
						var notification = new Notification(notificationTitle, notificationOptions)
						notification.onclick = function (event) {
							event.preventDefault() //prevent the browser from focusing the Notification's tab
							getURL(payload.data)
							// window.open(payload.data.tag, router.push('/home'));
							notification.close()
						}
					} catch (err) {}
				}
			}
		}
	})
}

if (firebase.messaging.isSupported()) {
	Notification.requestPermission().then((permission) => {
		if (permission === 'granted') {
			getDeviceId()
		} else {
			window.console.log('Unable to get permission to notify.')
		}
	})
}

export function getDeviceId() {
	if (firebase.messaging.isSupported()) {
		messaging
			.requestPermission()
			.then(function () {
				messaging
					.getToken({ vapidKey: 'BOyM9ZSqWubDd1X7LnAA8zQNC1IEo-uve66K8ucf6xc6H_t2snvrMd8_GiGIehhCRgqYR0BBssLFLkXKZ89s5to' })
					.then(function (currentToken) {
						if (currentToken) {
							Cookies.set('fcm_id', currentToken, {
								expires: 90,
							})
							let data = { fcm_id: currentToken }
							storeFCMId(data)
						}
					})
					.catch(function (err) {
						window.console.log('An error occurred while retrieving token. ')
					})
			})
			.catch(function (err) {
				window.console.log('Error')
			})
	}
}

export function deleteFCMToken() {
	// if (Cookies.get("fcm_id") !== undefined) {
	//     if (firebase.messaging.isSupported()) {
	//         messaging
	//             .deleteToken(Cookies.get('fcm_id'))
	//             .then((resp) => {
	//                 console.log(resp);
	//             })
	//             .catch((err) => {
	//                 return;
	//             });
	//     }
	// }
}

export function getURL(data) {
	if (data.code === 'L201' || data.code === 'L200') {
		return router.push('/lists')
	} else if (data.code === 'B201' || data.code === 'B200') {
		return router.push('/home')
	} else if (data.code === 'D200') {
		return router.push('/deliveries')
	}
}

async function getData(payload) {
	errorLogs('loder loading ' + store.state.loader.loading + ' id of list, bid ' + payload.id)
	if (store.state.loader.loading === false) {
		if (router.currentRoute.name === 'home' || router.currentRoute.path === '/') {
			if (payload.code === 'L201') {
				store.dispatch('getDashboardData')
			}
			if (payload.code === 'L200') {
				store.dispatch('getDashboardData')
			}
		}

		if (router.currentRoute.name === 'lists') {
			if (payload.code === 'L201') {
				errorLogs('list page notification status code L201')
				getListNotification(payload)
			}
			if (payload.code === 'L200') {
				errorLogs('list page notification status code L200')
				getListData(payload)
			}
		}

		if (payload.code === 'B201' || payload.code === 'B200') {
			errorLogs('bids notification status code B201 || B200')
			getBidNotification(payload)

			if (router.currentRoute.name === 'home' || router.currentRoute.path === '/') {
				store.dispatch('getDashboardData')
			}
		}

		if (payload.code === 'D200') {
			errorLogs('delivery notification status code D200')
			getDeliveryNotification(payload)

			if (router.currentRoute.name === 'home' || router.currentRoute.path === '/') {
				store.dispatch('getDashboardData')
			}
		}

		if (payload.code === 'L204') {
			errorLogs('list cancel status code L204')
			store.commit('cancelList', JSON.parse(JSON.stringify(payload.id)))
		}
	}
}

async function getListNotification(payload) {
	store.commit('background')
	let data = await getNotification(payload.id, 'list')
	if (data.data) {
		data = {
			list: data.data.clist,
			bid: {},
			contractor: data.data.contractor,
			notification: data.data,
		}
		store.commit('listReset')
		store.commit('listData', JSON.parse(JSON.stringify(data)))
	} else {
		store.commit('cancelList', JSON.parse(JSON.stringify(payload.id)))
	}
	errorLogs('list notification funcation call')
}

async function getListData(payload) {
	store.commit('background')
	let data = await getList(payload.id)
	if (data.data) {
		let contractor = {}
		if (data.contractor) {
			contractor = data.contractor
		}
		let bid = {}
		if (data.last_bid) {
			bid = data.last_bid
		}
		data = {
			list: data.data,
			bid: bid,
			contractor: contractor,
			notification: {},
		}
		store.commit('listReset')
		store.commit('listData', JSON.parse(JSON.stringify(data)))
	} else {
		store.commit('cancelList', JSON.parse(JSON.stringify(payload.id)))
	}
	errorLogs('list data funcation call')
}

async function getBidNotification(payload) {
	store.commit('background')
	let data = await getNotification(payload.id, 'bid')
	let contractor = {}
	if (data.data.clist.contractor) {
		contractor = data.data.clist.contractor
	}
	if (data.data) {
		data = {
			list: data.data.clist,
			bid: data.data.bid,
			contractor: contractor,
			notification: data.data,
		}
		store.commit('listReset')
		store.commit('listData', JSON.parse(JSON.stringify(data)))
	}
	errorLogs('bid notification funcation call')
}

async function getDeliveryNotification(payload) {
	store.commit('background')
	let data = await getDelivery(payload.id)

	if (data.data) {
		data = {
			list_id: data.data.list_id,
			status: data.data.status,
		}
		store.commit('delivered', JSON.parse(JSON.stringify(data)))
	}
	errorLogs('delivered notification funcation call')
}

async function getBackgroundUpdate(payload) {
	if (payload.code === 'BLC') {
		let data = {
			list_id: payload.id,
			idle_time: payload.dataset,
		}
		store.commit('resetListCounter', JSON.parse(JSON.stringify(data)))
	} else if (payload.code === 'BAB') {
		let data = {
			list_id: payload.id,
			active_bids: payload.dataset,
		}
		store.commit('changeActiveBids', JSON.parse(JSON.stringify(data)))
	} else if (payload.code === 'BLAB') {
		let data = {
			list_id: payload.id,
			status: payload.dataset,
		}
		store.commit('listBidAccepted', JSON.parse(JSON.stringify(data)))
	} else if (payload.code === 'BBTSC') {
		let data = {
			list_id: payload.id,
			tag_status: payload.dataset,
		}
		store.commit('bidTagStatus', JSON.parse(JSON.stringify(data)))
	}
}

export { messaging, firebase }
