const state = {
	setting_tab: {
		tab: 'company_profile',
	},

	company: {
		name: '',
		address: '',
		main_contact: '',
		title: '',
		phone: '',
		email: '',
		place_id: '',
		session_token: '',
		company_profile: null,
		company_profile_link: '',
	},

	distributor: {
		distributor_map_id: '',
		company_id: '',
		distributor_id: '',
		role: '',
	},

	category: {
		category_id: '',
	},

	preference: {
		bid_email: '',
		list_email: '',
		delivery_email: '',
	},

	authorize_user: {
		authorize_user: [],
	},

	delivery_zone: {},

	bank_information: {
		business_name: '-',
		contact_number: '-',
		email: '-',
		bank_name: '-',
		account_no: '-',
		routing_no: '-',
		status: '-',
	},

	transaction_history: [],
}

const mutations = {
	settingTab: (state, tab_name) => {
		state.setting_tab.tab = tab_name
	},

	distributorInfo: (state, distributor) => {
		state.distributor.distributor_map_id = distributor.distributor_map_id
		state.distributor.company_id = distributor.company_id
		state.distributor.distributor_id = distributor.distributor_id
		state.distributor.role = distributor.role
	},

	companyInfo: (state, company) => {
		state.company.name = company.name
		state.company.address = company.address
		state.company.main_contact = company.main_contact
		state.company.title = company.title
		state.company.phone = company.phone != null ? company.phone.replace(/(\d{1,3})(\d{1,3})(\d{1,4})/g, '($1)-$2-$3') : null
		state.company.email = company.email
		state.company.company_profile_link = company.company_profile_link
		state.company.place_id = company.place_id
		state.company.account_number = company.account_number
		state.company.routing_number = company.routing_number
	},

	categoryInfo: (state, category) => {
		state.category.category_id = ''
		state.category.category_id = category.category_id
	},

	authorizeUserInfo: (state, authorize_user) => {
		state.authorize_user.authorize_user = []
		if (authorize_user.length > 0) {
			authorize_user.forEach((item) => {
				let ob = {
					user_id: item.distributor.id,
					email: item.distributor.email,
				}
				state.authorize_user.authorize_user.push(ob)
			})
		}
	},

	deliveryZoneInfo: (state, delivery_zone) => {
		state.delivery_zone = delivery_zone
	},

	updateCompanyValue: (state, data) => {
		let fieldname = data.fieldname
		fieldname === 'name' ? (state.company.name = data.value) : ''
		fieldname === 'main_contact' ? (state.company.main_contact = data.value) : ''
		fieldname === 'title' ? (state.company.title = data.value) : ''
		fieldname === 'phone' ? (state.company.phone = data.value) : ''
		fieldname === 'email' ? (state.company.email = data.value) : ''
		fieldname === 'address' ? (state.company.address = data.value) : ''
		fieldname === 'place_id' ? (state.company.place_id = data.value) : ''
		fieldname === 'company_profile' ? (state.company.company_profile = data.value) : {}
	},

	preferenceInfo: (state, data) => {
		state.preference.bid_email = data.bid_email
		state.preference.list_email = data.list_email
		state.preference.delivery_email = data.delivery_email
	},

	transactionHistory: (state, data) => {
		state.transaction_history = []
		state.transaction_history = data
	},

	bankInformation: (state, data) => {
		state.bank_information.business_name = data.business_name
		state.bank_information.contact_number = data.contact_number
		state.bank_information.email = data.email
		state.bank_information.bank_name = data.bank_name
		state.bank_information.account_no = data.account_no
		state.bank_information.routing_no = data.routing_no
		state.bank_information.status = data.status
	},
}

export default {
	state,
	mutations,
}
