export const Base_URL = process.env.VUE_APP_BASE_URL

export const APP_URL = process.env.VUE_APP_APP_URL

export const Build_Version = process.env.VUE_APP_BUILD_VERSION

export const Local_Base_URL = process.env.VUE_APP_LOCAL_BASE_URL

export const Map_Key = process.env.VUE_APP_MAP_KEY

export const Debug_Logs = process.env.VUE_APP_DEBUG_LOGS

export const client_id = process.env.VUE_APP_CLIENT_ID

export const FIREBASE_COLLECTION_NAME_PREFIX = process.env.VUE_APP_FIREBASE_COLLECTION_NAME_PREFIX

export const FIREBASE_KEY = process.env.VUE_APP_FIREBASE_KEY
