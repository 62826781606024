import Cookies from 'js-cookie'
import { router } from '../../router/index'
import { AXIOS } from '../../plugins/axios'

const state = {
	access_token: Cookies.get('access_token') || '',
	user_data: {
		id: '',
		first_name: '',
		last_name: '',
		email: '',
		phone: '',
		role: '',
		company_id: '',
		account_id: true,
		address: '',
	},
	status: '',
	hasLoadedOnce: false,
	fcm_id: [],
	error: '',
	previous_route: '',
	not_supported: false,
	alert: {
		show: false,
		message: '',
		type: '',
	},
}

const getters = {
	isAuthenticated: (state) => !!state.access_token,
	isAuthorization: (state) => state.user_data.role || '',
	authStatus: (state) => state.status,
}

const actions = {
	authRequest: ({ commit }, payload) => {
		let access_token = 'Bearer ' + payload.access_token
		Cookies.set('access_token', access_token, { expires: 90 })
		AXIOS.defaults.headers.common['Authorization'] = access_token
		commit('authSuccess', access_token)
	},
	authLogout: ({ commit }) => {
		Cookies.remove('access_token')
		Cookies.remove('refresh_token')
		Cookies.remove('fcm_id')
		commit('authLogout')
		// router.push("/login");
	},
	authUser: ({ commit }, payload) => {
		commit('authUser', payload)
	},
	showAlert: ({ commit }, payload) => {
		commit('showAlert', payload)
	},
}

const mutations = {
	authRequest: (state) => {
		state.status = 'loading'
	},
	authUser: (state, user) => {
		let first_name
		let last_name
		if (user.fullname != null) {
			first_name = user.fullname.split(' ').slice(0, -1).join(' ')
			last_name = user.fullname.split(' ').slice(-1).join(' ')
		}

		state.user_data.id = user.id
		state.user_data.first_name = first_name
		state.user_data.last_name = last_name
		state.user_data.email = user.email
		state.user_data.phone = user.phone != null ? user.phone.replace(/(\d{1,3})(\d{1,3})(\d{1,4})/g, '($1)-$2-$3') : null
		state.user_data.role = user.role
		state.user_data.company = user.company
		state.user_data.company_id = user.company_id
		state.user_data.account_id = user.account_id
		state.user_data.address = user.address
		state.fcm_id = user.fcm === '' ? [] : user.fcm
	},
	authSuccess: (state, access_token) => {
		state.status = 'success'
		state.access_token = access_token
		state.hasLoadedOnce = true
	},
	authLogout: (state) => {
		state.access_token = ''
		state.user_data.first_name = ''
		state.user_data.last_name = ''
		state.user_data.email = ''
		state.user_data.phone = ''
		state.user_data.role = ''
		state.user_data.company_id = ''
	},
	errorMessage: (state, payload) => {
		state.error = payload
	},
	previousRoute: (state, path) => {
		state.previous_route = path
	},
	updateUserValue: (state, data) => {
		let fieldname = data.fieldname
		fieldname === 'first_name' ? (state.user_data.first_name = data.value) : ''
		fieldname === 'last_name' ? (state.user_data.last_name = data.value) : ''
		fieldname === 'phone' ? (state.user_data.phone = data.value) : ''
		fieldname === 'email' ? (state.user_data.email = data.value) : ''
	},
	showAlert: (state, payload) => {
		state.alert.show = payload.show
		state.alert.message = payload.message
		state.alert.type = payload.type
	},
}

export default {
	state,
	getters,
	actions,
	mutations,
}
