const state = {
    passwordcomplete:false,
    accountcomplete:false,
    categorycomplete:false,
    authorizecomplete:false,
    deliverycomplete:false
}

const actions = {
    passwordcomplete({ commit }) {
        commit("passwordcomplete");
    }
}

const mutations = {
    passwordcomplete(state) {
        state.passwordcomplete = true;
    },
    accountcomplete(state) {
        state.accountcomplete = true;
    },
    categorycomplete(state) {
        state.categorycomplete = true;
    },
    authorizecomplete(state) {
        state.authorizecomplete = true;
    },
    deliverycomplete(state) {
        state.deliverycomplete = true;
    },
}

export default {
    state,
    actions,
    mutations,
}

