import Vue from 'vue'
import Vuex from 'vuex'

import auth from './modules/auth'
import loader from './modules/loader'
import registerstep from './modules/registersetp'
import list from './modules/list'
import register from './modules/register'
import icons from './modules/icons'
import setting from './modules/setting'
import listobject from './modules/listobject'
import deliveryobject from './modules/deliveryobject'
import chathaggle from './modules/chathaggle'

Vue.use(Vuex)

export const store = new Vuex.Store({
	modules: {
		auth,
		loader,
		registerstep,
		list,
		register,
		setting,
		icons,
		listobject,
		deliveryobject,
		chathaggle
	}
})

//  store;
