const state = {
    active_tab: 'password',
    register_user: {
        user_id: '',
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        password: '',
        status: '',
        trems: false
    },

    company: {
        company_id: '',
        email: ''
    },

    category: {
        category_id: ''
    },

    authorize_user: {
        authorize_user: []
    },

    delivery_zone: {

    }
}

const mutations = {
    registerUser: (state, user) => {
        let first_name = '';
        let last_name = '';
        let phone = '';
        if (user.fullname != null) {
            first_name = user.fullname.split(' ').slice(0, -1).join(' ');
            last_name = user.fullname.split(' ').slice(-1).join(' ');
        }

        if (user.phone != null && user.phone) {
            phone = user.phone.replace(
                /(\d{1,3})(\d{1,3})(\d{1,4})/g,
                "($1)-$2-$3"
            );
        }

        state.register_user.user_id = user.id;
        state.register_user.first_name = first_name;
        state.register_user.last_name = last_name;
        state.register_user.email = user.email;
        state.register_user.phone = phone;
        state.register_user.status = user.status;
    },

    registerCompany: (state, company) => {
        state.company.company_id = company.company_id;
        state.company.email = company.email;
    },

    registerCategory: (state, category) => {
        state.category.category_id = category.category_id;
    },

    registerAuthorize: (state, authorize_user) => {
        state.authorize_user.authorize_user = [];
        if (authorize_user.length > 0) {
            authorize_user.forEach(item => {
                let ob = {
                    user_id: item.distributor.id,
                    email: item.distributor.email
                }
                state.authorize_user.authorize_user.push(ob)
            });
        }
    },

    registerDeliveryZone: (state, delivery_zone) => {
        state.delivery_zone = delivery_zone
    },

    registerTab: (state, tab_name) => {
        state.active_tab = tab_name;
    },
}

export default {
    state,
    mutations,
}
