const state = {
	list_object: [],
	search: '',
}

const mutations = {
	listObject: (state, dataset) => {
		state.list_object = []
		dataset.dataset.forEach((item) => {
			let listitems = []
			let biditems = []
			let reason_ids = []
			let list = {}
			let bid = {}
			let contractor = {}
			let listDistCompanyId = ''

			item.listitems.forEach((item) => {
				let ob = {
					name: item.name,
					qty: item.qty,
					price: 0.0,
					notes: null,
					show_note: false,
					url: item.url,
				}
				listitems.push(ob)
			})

			listitems.forEach((item) => {
				let data = item.notes != null
				item['show_note'] = data
			})

			list = {
				list_id: item.list_id,
				contractor_id: item.contractor_id,
				category_id: item.category_id,
				list_name: item.list_name,
				delivery_date: item.delivery_date,
				pickup: item.pickup,
				address: item.address,
				delivery_time: item.delivery_time,
				notes: item.notes,
				status: item.status,
				idle_time: item.idle_time,
				latitude: item.latitude,
				longitude: item.longitude,
				active_bids: item.active_bids,
				listitems: listitems,
				url: item.url,
			}

			if (item.last_bid) {
				if (item.last_bid.reason_ids) {
					item.last_bid.reason_ids.forEach((item) => {
						reason_ids.push(item)
					})
				} else {
					reason_ids.push(0)
				}

				item.last_bid.biditems.forEach((item) => {
					let ob = {
						name: item.name,
						qty: item.qty,
						price: item.price,
						notes: item.notes,
						modification: item.modification,
						show_note: false,
						url: item.url,
					}

					biditems.push(ob)
				})

				biditems.forEach((item) => {
					let data = item.notes != null
					item['show_note'] = data
				})

				bid = {
					bid_id: item.last_bid.bid_id,
					list_id: item.last_bid.list_id,
					date_of_delivery: item.last_bid.date_of_delivery,
					sub_total: item.last_bid.sub_total,
					shipping: item.last_bid.shipping,
					service_fee: item.last_bid.service_fee,
					total_amount: item.last_bid.total_amount,
					modification: item.last_bid.modification,
					reason_ids: reason_ids,
					comments: item.last_bid.comments,
					tag_status: item.last_bid.tag_status,
					status: item.last_bid.status,
					created_at: item.last_bid.created_at,
					time_of_delivery: item.last_bid.time_of_delivery,
					latitude: item.last_bid.latitude,
					longitude: item.last_bid.longitude,
					start_time: item.last_bid.start_time,
					end_time: item.last_bid.end_time,
					biditems: biditems,
				}
			}

			if (item.contractor) {
				contractor = {
					fullname: item.contractor.fullname,
					phone: item.contractor.phone,
				}
			}

			listDistCompanyId = `${dataset.distCompanyId}DC` + `${item.list_id}L`

			let ob = {
				list: list,
				bid: bid,
				contractor: contractor,
				listDistCompanyId: listDistCompanyId,
			}

			state.list_object.push(ob)
		})
	},
}

export default {
	state,
	mutations,
}
