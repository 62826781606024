<template>
	<div style="overflow-x: hidden">
		<v-row style="padding: 40px">
			<img :src="$store.state.icons.icons['favicon-32x32']" alt />
		</v-row>
		<v-row class="align-center justify-center" style="height: 70vh">
			<v-col cols="11" sm="2" md="4"></v-col>
			<v-col cols="11" sm="8" md="4">
				<v-form v-model="valid" ref="form" lazy-validation>
					<v-card tag="div" elevation="0" outlined color="#F7F9FC">
						<p class="text-sm-center my-heading">Log in</p>

						<v-row class="p-0">
							<v-col cols="12" class="pb-0">
								<label>Email address</label>
							</v-col>
							<v-col cols="12" class="pt-1">
								<v-text-field
									single-line
									outlined
									dense
									:hide-details="Show"
									height="48"
									color="#CACEDA"
									type="text"
									placeholder="Email address"
									v-model="loginUser.email"
									:rules="emailRules"
									required
								></v-text-field>
							</v-col>
						</v-row>

						<v-row class="p-0" v-on:keydown.enter.prevent="logIn">
							<v-col cols="6" class="pb-0">
								<label>Password</label>
							</v-col>
							<v-col cols="6" class="pb-0 text-right">
								<label>
									<router-link to="/resetpassword">Forgot password?</router-link>
								</label>
							</v-col>
							<v-col cols="12" class="pt-1">
								<v-text-field
									single-line
									outlined
									dense
									:hide-details="Show"
									height="48"
									color="#CACEDA"
									type="password"
									placeholder="Password"
									v-model="loginUser.password"
									:rules="passwordRules"
									required
								></v-text-field>
							</v-col>
						</v-row>

						<v-row class="align-center justify-center p-0 custom-error" :style="{ display: ShowMessage }">
							<v-col cols="12">{{ this.error_message }}</v-col>
						</v-row>

						<v-row class="p-0">
							<v-col cols="12">
								<v-btn class="my-p-btn my_elevation" id="my_elevation" depressed block @click="logIn" :disabled="!valid">Log in</v-btn>
							</v-col>
						</v-row>
					</v-card>
				</v-form>
			</v-col>
			<v-col cols="11" sm="2" md="4"></v-col>
		</v-row>
	</div>
</template>

<script>
import { AXIOS } from '../../plugins/axios'
import Cookies from 'js-cookie'
import moment from 'moment-timezone'
import { FIREBASE_COLLECTION_NAME_PREFIX } from '../../config/variable'
import firebase from 'firebase/app'

export default {
	data() {
		return {
			loginUser: {
				email: '',
				password: '',
				role: 'D',
				timezone: '',
			},
			show: true,
			valid: true,
			passwordRules: [(v) => !!v || 'Password is required'],
			emailRules: [(v) => !!v || 'E-mail is required', (v) => /.+@.+/.test(v) || 'E-mail must be valid'],
			error_message: '',
			show_message: 'none',
		}
	},

	computed: {
		Show() {
			return (this.show = !this.valid ? false : true)
		},
		ShowMessage() {
			return (this.show_message = !this.error_message ? 'none' : 'block')
		},
	},

	mounted() {
		AXIOS.defaults.headers.common['Authorization'] = ''
		AXIOS.defaults.headers.common['X-Authorization'] = ''
	},

	methods: {
		async logIn() {
			try {
				if (Cookies.get('access_token') !== undefined) {
					this.$router.push({ path: '/' })
				}
				if (this.$refs.form.validate()) {
					this.loginUser.timezone = moment.tz.guess()
					const { data, status } = await AXIOS.post('auth/login', this.loginUser)

					if (status) {
						let remember = ''
						this.$store.dispatch('authUser', data.user)
						let access_token = data.access_token
						let refresh_token = data.refresh_token
						this.$store.dispatch('authRequest', { access_token })
						Cookies.set('refresh_token', refresh_token, {
							expires: 90,
						})
						AXIOS.defaults.headers.common['X-Authorization'] = refresh_token
						this.$router.push({ path: '/' })

						if (data.user) {
							let company_data = await AXIOS.get('distributor/company/' + data.user.company_id)
							setTimeout(async () => {
								if (company_data.data.data) {
									await firebase
										.auth()
										.signInWithCustomToken(data.user.firebase_token)
										.then((userCredential) => {
											// Signed in
											var user = userCredential.user
											let userOb = {
												id: company_data.data.data.company_id,
												email: company_data.data.data.email,
												fullname: company_data.data.data.name,
												phone: company_data.data.data.phone,
												address: company_data.data.data.address,
												profile_link: company_data.data.data.company_profile_link ? company_data.data.data.company_profile_link : '',
												fcm_id: Cookies.get('fcm_id') ? [Cookies.get('fcm_id')] : [''],
												chatting_with: '',
												isUserOnline: false,
												role: 'D',
												status: '',
											}

											let docRef = firebase
												.firestore()
												.collection(FIREBASE_COLLECTION_NAME_PREFIX + 'users')
												.doc(`${data.user.company_id}DC`)
											docRef.get().then((doc) => {
												if (doc.data() !== undefined) {
													console.log(doc.data().fcm_id.length, '--')
													if (doc.data().fcm_id.length > 0) {
														if (Cookies.get('fcm_id') !== '') {
															let fcmToken = doc.data().fcm_id
															fcmToken.push(Cookies.get('fcm_id'))

															docRef.update('fcm_id', fcmToken)
														}
													} else {
														docRef.set(userOb)
													}
												} else {
													docRef.set(userOb)
												}
											})
										})
										.catch()
								}
							}, 1000)
						}
					}
				}
			} catch (error) {
				if (error.response && error.response.data) {
					this.error_message = ''
					const response = error.response
					if (response === undefined || response.status === 500) {
						this.$router.push({ path: '/500' })
					}
					this.error_message = response.data.message
				}
			}
		},
	},
}
</script>
