import Vue from "vue";
import VueRouter from "vue-router";
import { store } from "../store/store";
import Cookies from "js-cookie";

Vue.use(VueRouter);

import Login from "../views/auth/Login";
import Register from "../views/register/Rlayout";

function lazyLoad(view) {
    return () => import(`@/views/${view}.vue`);
}

const ifNotAuthenticated = (to, from, next) => {
    if (!store.getters.isAuthenticated) {
        next();
        return;
    }
    next("/");
};

const ifAuthenticated = (to, from, next) => {
    if (store.getters.isAuthenticated) {
        if (Cookies.get("access_token") == "undefined") {
            store.dispatch("authLogout");
        }
        next();
        return;
    }
    next("/login");
};

const routes = [
    {
        path: "/login",
        name: "login",
        component: Login,
        beforeEnter: ifNotAuthenticated,
    },
    {
        path: "/resetpassword",
        name: "resetpassword",
        component: lazyLoad("auth/ResetPassword"),
        beforeEnter: ifNotAuthenticated,
    },
    {
        path: "/resetlink",
        name: "resetlink",
        component: lazyLoad("auth/ResetLink"),
        beforeEnter: ifNotAuthenticated,
    },
    {
        path: "/register",
        name: "register",
        component: Register,
        beforeEnter: ifNotAuthenticated,
    },
    {
        path: "/",
        component: lazyLoad("menubars/Dashboard"),
        beforeEnter: ifAuthenticated,
        children: [
            {
                path: "",
                component: lazyLoad("home/Home"),
                beforeEnter: ifAuthenticated,
            },
            {
                path: "/home",
                name: "home",
                component: lazyLoad("home/Home"),
                beforeEnter: ifAuthenticated,
            },
            {
                path: "/lists",
                name: "lists",
                component: lazyLoad("list/List"),
            },
            {
                path: "/deliveries",
                name: "deliveries",
                component: lazyLoad("delivery/Delivery"),
            },
            {
                path: "/settings",
                name: "settings",
                component: lazyLoad("setting/Setting"),
            },
            {
                path: "/search",
                name: "search",
                component: lazyLoad("menubars/Searchdata"),
            },
        ],
    },
    {
        path: "/sidemenu",
        name: "sidemenu",
        component: lazyLoad("menubars/Sidemenu"),
        beforeEnter: ifAuthenticated,
    },
    {
        path: "/404",
        component: lazyLoad("404"),
    },
    {
        path: "/500",
        component: lazyLoad("500"),
    },
    // {
    //     path: '*',
    //     redirect: '/404'
    // },
];

export const router = new VueRouter({
    mode: "history",
    routes,
});
