import { router } from '../router/index'
import { AXIOS } from '../plugins/axios'
import { store } from '../store/store'

export async function skipSettings() {
	let user_id = JSON.parse(localStorage.getItem('user_id'))
	await userActive(user_id)
	localStorage.removeItem('user_id')
	localStorage.removeItem('company_id')
	store.commit('registerUser', {})
	store.commit('registerCompany', {})
	store.commit('registerCategory', {})
	store.commit('registerAuthorize', '')
	store.commit('registerDeliveryZone', {})
	window.location.reload()
	router.push('/login')
}

function Get(url) {
	return new Promise((resolve, reject) => {
		AXIOS.get(url)
			.then(response => {
				if (response.status === 200 && !response.data.dataset && !response.data.data) {
					store.commit('errorMessage', response.data.message)
				}
				resolve(response)
			})
			.catch(error => {
				if (error.response === undefined) {
					router.push({ path: '/500' })
				} else if (error.response.status === 404) {
					router.push('/404')
				} else if (error.response.status === 500) {
					router.push('/500')
				}
				reject
			})
	})
}

function GetNormal(url) {
	return new Promise((resolve, reject) => {
		AXIOS.get(url)
			.then(response => {
				resolve(response)
			})
			.catch(error => {
				if (error.response === undefined) {
					router.push({ path: '/500' })
				} else if (error.response.status === 404) {
					router.push('/404')
				} else if (error.response.status === 500) {
					router.push('/500')
				}
				reject
			})
	})
}

function Post(url, data) {
	return new Promise((resolve, reject) => {
		AXIOS.post(url, data)
			.then(response => {
				if (response.status === 200 && !response.data.dataset && !response.data.data) {
					store.commit('errorMessage', response.data.message)
				}
				resolve(response)
			})
			.catch(error => {
				if (error.response === undefined) {
					router.push({ path: '/500' })
				} else if (error.response.status === 404) {
					router.push('/404')
				} else if (error.response.status === 500) {
					router.push('/500')
				} else if (error.response.status === 422) {
					store.commit('errorMessage', error.response.data.message)
				}
				reject
			})
	})
}

export async function setUser() {
	try {
		let user_id = router.currentRoute.query.user_id
		let company_id = router.currentRoute.query.company_id

		if (user_id) {
			localStorage.setItem('user_id', JSON.stringify(user_id))
			localStorage.setItem('company_id', JSON.stringify(company_id))
		}
		if (store.state.register.register_user.user_id === '') {
			user_id = JSON.parse(localStorage.getItem('user_id'))
			company_id = JSON.parse(localStorage.getItem('company_id'))
			if (user_id === null) {
				router.push('/login')
			} else {
				let data = await getUser(user_id, company_id)
				store.commit('registerUser', JSON.parse(JSON.stringify(data.data.user)))
				store.commit('registerCompany', JSON.parse(JSON.stringify(data.data.company)))
				store.commit('registerCategory', JSON.parse(JSON.stringify(data.data.category)))
				store.commit('registerAuthorize', JSON.parse(JSON.stringify(data.data.authorize_user)))
				store.commit('registerDeliveryZone', JSON.parse(JSON.stringify(data.data.delivery_zone)))
			}
		}
	} catch (error) {
		console.log(error)
		router.push('/404')
	}
}

export async function getUser(user_id, company_id) {
	let resp
	try {
		resp = await Get('distributor/register/user/' + user_id + '/' + company_id)
		return resp.data
	} catch (e) {
		console.log(e)
		// router.push("/500");
		return e.response
	}
}

export async function getprivacy() {
	let resp
	try {
		resp = await GetNormal('privacy-policy')
		return resp.data
	} catch (e) {
		console.log(e)
		// router.push("/500");
		return e.response
	}
}

export async function getTerms() {
	let resp
	try {
		resp = await GetNormal('terms-conditions')
		return resp.data
	} catch (e) {
		console.log(e)
		// router.push("/500");
		return e.response
	}
}

export async function getReturnPolicy() {
	let resp
	try {
		resp = await GetNormal('return-policy')
		return resp.data
	} catch (e) {
		console.log(e)
		// router.push("/500");
		return e.response
	}
}

export async function getDisclaimer() {
	let resp
	try {
		resp = await GetNormal('disclaimer')
		return resp.data
	} catch (e) {
		console.log(e)
		// router.push("/500");
		return e.response
	}
}

export async function userActive(user_id) {
	let resp
	try {
		resp = await Post('distributor/register/distributor-active/' + user_id)
		return resp.data
	} catch (e) {
		console.log(e)
		// router.push("/500");
		return e.response
	}
}

export async function userRegister(user_id, data) {
	let resp
	try {
		resp = await Post('distributor/register/user-register/' + user_id, data)
		return resp.data
	} catch (e) {
		console.log(e)
		// router.push("/500");
		return e.response
	}
}

export async function userProfile(user_id, data) {
	let resp
	try {
		resp = await Post('distributor/register/user-profile/' + user_id, data)
		return resp.data
	} catch (e) {
		console.log(e)
		// router.push("/500");
		return e.response
	}
}

export async function productCategory(user_id, data) {
	let resp
	try {
		resp = await Post('distributor/register/category/' + user_id, data)
		return resp.data
	} catch (e) {
		console.log(e)
		// router.push("/500");
		return e.response
	}
}

export async function addAuthorizeUser(company_id, data) {
	let resp
	try {
		resp = await Post('distributor/register/authorizeuser/' + company_id, data)
		return resp.data
	} catch (e) {
		console.log(e)
		// router.push("/500");
		return e.response
	}
}

export async function removeAuthorizeUser(user_id, company_id) {
	let resp
	try {
		resp = await Post('distributor/register/remove-authorizeuser/' + user_id + '/' + company_id)
		return resp.data
	} catch (e) {
		console.log(e)
		// router.push("/500");
		return e.response
	}
}

export async function deliveryZoneStore(user_id, company_id, data) {
	let resp
	try {
		resp = await Post('distributor/register/deliveryzone/' + user_id + '/' + company_id, data)
		return resp.data
	} catch (e) {
		console.log(e)
		// router.push("/500");
		return e.response
	}
}
