var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"r-layout"},[_c('v-row',{staticClass:"ma-0 flex-lg-row flex-md-column"},[_c('v-col',{staticClass:"side-menu",attrs:{"cols":"12","lg":"2","md":"12"}},[_c('img',{attrs:{"src":_vm.$store.state.icons.icons['favicon-32x32'],"alt":""}}),_c('v-row',{staticClass:"col-lg-12 col-md-6 col-12",staticStyle:{"padding-top":"48px","padding-left":"0"},attrs:{"no-gutters":""}},[_c('p',{staticClass:"text-center my-heading",staticStyle:{"white-space":"nowrap"}},[_vm._v(" Simplify the build. ")]),_c('p',{staticClass:"simple-text"},[_vm._v(" Digital construction management tools empowering productivity. ")])]),_c('v-row',{staticClass:"registration-list",attrs:{"no-gutters":""}},[_c('div',{staticClass:"d-lg-block d-flex"},[_c('div',{class:{
              active: this.$store.state.register.active_tab === 'password',
            }},[(this.$store.state.registerstep.passwordcomplete)?_c('v-row',{staticClass:"menu-link-complete",attrs:{"no-gutters":""}},[_c('div',{staticClass:"number-box text-center"},[_c('img',{staticStyle:{"height":"9px"},attrs:{"src":_vm.$store.state.icons.icons['Tick'],"alt":""}})]),_c('p',{staticClass:"link-text d-sm-flex d-none align-center"},[_vm._v("Password")])]):_c('v-row',{staticClass:"menu-link",attrs:{"no-gutters":""}},[_c('div',{staticClass:"number-box text-center"},[_c('p',{staticClass:"menu-number"},[_vm._v("1")])]),_c('p',{staticClass:"link-text d-sm-flex align-center d-none",class:{
              'd-flex': this.$store.state.register.active_tab === 'password',
            }},[_vm._v("Password")])])],1),_c('div',{class:{
              active: this.$store.state.register.active_tab === 'account',
            }},[(this.$store.state.registerstep.accountcomplete)?_c('v-row',{staticClass:"menu-link-complete",attrs:{"no-gutters":""}},[_c('div',{staticClass:"number-box text-center"},[_c('img',{staticStyle:{"height":"9px"},attrs:{"src":_vm.$store.state.icons.icons['Tick'],"alt":""}})]),_c('p',{staticClass:"link-text d-sm-flex d-none align-center"},[_vm._v("Account information")])]):_c('v-row',{staticClass:"menu-link",attrs:{"no-gutters":""}},[_c('div',{staticClass:"number-box text-center"},[_c('p',{staticClass:"menu-number"},[_vm._v("2")])]),_c('p',{staticClass:"link-text d-sm-flex d-none align-center",class:{
              'd-flex': this.$store.state.register.active_tab === 'account',
            }},[_vm._v("Account information")])])],1),_c('div',{class:{
              active: this.$store.state.register.active_tab === 'product',
            }},[(this.$store.state.registerstep.categorycomplete)?_c('v-row',{staticClass:"menu-link-complete",attrs:{"no-gutters":""}},[_c('div',{staticClass:"number-box text-center"},[_c('img',{staticStyle:{"height":"9px"},attrs:{"src":_vm.$store.state.icons.icons['Tick'],"alt":""}})]),_c('p',{staticClass:"link-text d-sm-flex d-none align-center"},[_vm._v("Product categories")])]):_c('v-row',{staticClass:"menu-link",attrs:{"no-gutters":""}},[_c('div',{staticClass:"number-box text-center"},[_c('p',{staticClass:"menu-number"},[_vm._v("3")])]),_c('p',{staticClass:"link-text d-sm-flex d-none align-center",class:{
              'd-flex': this.$store.state.register.active_tab === 'product',
            }},[_vm._v("Product categories")])])],1),_c('div',{class:{
              active: this.$store.state.register.active_tab === 'authorize',
            }},[(this.$store.state.registerstep.authorizecomplete)?_c('v-row',{staticClass:"menu-link-complete",attrs:{"no-gutters":""}},[_c('div',{staticClass:"number-box text-center"},[_c('img',{staticStyle:{"height":"9px"},attrs:{"src":_vm.$store.state.icons.icons['Tick'],"alt":""}})]),_c('p',{staticClass:"link-text d-sm-flex d-none align-center"},[_vm._v("Authorize users")])]):_c('v-row',{staticClass:"menu-link",attrs:{"no-gutters":""}},[_c('div',{staticClass:"number-box text-center"},[_c('p',{staticClass:"menu-number"},[_vm._v("4")])]),_c('p',{staticClass:"link-text d-sm-flex d-none align-center",class:{
              'd-flex': this.$store.state.register.active_tab === 'authorize',
            }},[_vm._v("Authorize users")])])],1),_c('div',{class:{
              active: this.$store.state.register.active_tab === 'delivery',
            }},[(this.$store.state.registerstep.deliverycomplete)?_c('v-row',{staticClass:"menu-link-complete",attrs:{"no-gutters":""}},[_c('div',{staticClass:"number-box text-center"},[_c('img',{staticStyle:{"height":"9px"},attrs:{"src":_vm.$store.state.icons.icons['Tick'],"alt":""}})]),_c('p',{staticClass:"link-text d-flex align-center"},[_vm._v("Delivery zones")])]):_c('v-row',{staticClass:"menu-link",attrs:{"no-gutters":""}},[_c('div',{staticClass:"number-box text-center"},[_c('p',{staticClass:"menu-number"},[_vm._v("5")])]),_c('p',{staticClass:"link-text d-sm-flex align-center d-none",class:{
              'd-flex': this.$store.state.register.active_tab === 'delivery',
            }},[_vm._v("Delivery zones")])])],1)])])],1),_c('v-col',{staticClass:"side-content d-flex",staticStyle:{"padding":"40px","min-height":"100vh"},attrs:{"cols":""}},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(this.$store.state.register.active_tab === 'password')?_c('app-password'):_vm._e(),(this.$store.state.register.active_tab === 'account')?_c('app-account'):_vm._e(),(this.$store.state.register.active_tab === 'authorize')?_c('app-authorize'):_vm._e(),(this.$store.state.register.active_tab === 'product')?_c('app-product'):_vm._e(),(this.$store.state.register.active_tab === 'delivery')?_c('app-delivery'):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }