import firebase from 'firebase/app'
import { FIREBASE_COLLECTION_NAME_PREFIX } from '../../config/variable'
import { getcompany_id } from '../../services/homeservice'

const state = () => ({
	user: {},
	admin: {},
	users: [
		{
			id: 1,
			count: 0,
			isActive: true,
			lastMessage: 'Good morning. Comparing to yo',
			lastMsgTime: '',
			phoneNumber: '',
			name: 'John Doe',
			profileUrl: '',
			email: '',
		},
		{
			id: 2,
			count: 0,
			isActive: false,
			lastMessage: 'Something like that',
			lastMsgTime: '',
			phoneNumber: '',
			name: 'Simon Leghart',
			profileUrl: '',
			email: '',
		},
		{
			id: 3,
			count: 0,
			isActive: false,
			lastMessage: 'yeah, sure',
			lastMsgTime: '',
			phoneNumber: '',
			name: 'Joseph Kaminsky',
			profileUrl: '',
			email: '',
		},
		{
			id: 4,
			count: 0,
			isActive: false,
			lastMessage: 'alright',
			lastMsgTime: '',
			phoneNumber: '',
			name: 'Philip Mayor',
			profileUrl: '',
			email: '',
		},
		{
			id: 5,
			count: 0,
			isActive: false,
			lastMessage: 'New prices for plywood aren’t',
			lastMsgTime: '',
			phoneNumber: '',
			name: 'Jack Russel',
			profileUrl: '',
			email: '',
		},
		{
			id: 6,
			count: 0,
			isActive: false,
			lastMessage: 'Keep the things nice and anyway',
			lastMsgTime: '',
			phoneNumber: '',
			name: 'Wyatt Grems',
			profileUrl: '',
			email: '',
		},
		{
			id: 7,
			count: 0,
			isActive: false,
			lastMessage: 'new discounts for lumber materi',
			lastMsgTime: '',
			phoneNumber: '',
			name: 'Samir Hibu',
			profileUrl: '',
			email: '',
		},
		{
			id: 1,
			count: 0,
			isActive: true,
			lastMessage: 'Good morning. Comparing to yo',
			lastMsgTime: '',
			phoneNumber: '',
			name: 'John Doe',
			profileUrl: '',
			email: '',
		},
		{
			id: 2,
			count: 0,
			isActive: false,
			lastMessage: 'Something like that',
			lastMsgTime: '',
			phoneNumber: '',
			name: 'Simon Leghart',
			profileUrl: '',
			email: '',
		},
		{
			id: 3,
			count: 0,
			isActive: false,
			lastMessage: 'yeah, sure',
			lastMsgTime: '',
			phoneNumber: '',
			name: 'Joseph Kaminsky',
			profileUrl: '',
			email: '',
		},
		{
			id: 4,
			count: 0,
			isActive: false,
			lastMessage: 'alright',
			lastMsgTime: '',
			phoneNumber: '',
			name: 'Philip Mayor',
			profileUrl: '',
			email: '',
		},
		{
			id: 5,
			count: 0,
			isActive: false,
			lastMessage: 'New prices for plywood aren’t',
			lastMsgTime: '',
			phoneNumber: '',
			name: 'Jack Russel',
			profileUrl: '',
			email: '',
		},
		{
			id: 6,
			count: 0,
			isActive: false,
			lastMessage: 'Keep the things nice and anyway',
			lastMsgTime: '',
			phoneNumber: '',
			name: 'Wyatt Grems',
			profileUrl: '',
			email: '',
		},
		{
			id: 7,
			count: 0,
			isActive: false,
			lastMessage: 'new discounts for lumber materi',
			lastMsgTime: '',
			phoneNumber: '',
			name: 'Samir Hibu',
			profileUrl: '',
			email: '',
		},
	],
	users_search: null,
	collection_id: '',
	contractor_id: '',
	bid_id: '',
	bid_obj_id: '',
	lastReference: {},
	unreadMessageUserCount: 0,
})

const getters = {
	getUserList: (state) => {
		let users = state.users
		if (state.users_search) {
			users = users.filter((item) => item.name.toUpperCase().includes(state.users_search.toUpperCase()))
		}
		return users
	},

	getAdmin: (state) => {
		return state.admin
	},

	userName: (state) => {
		return state.user.name
	},

	chatUser: (state) => {
		return state.user
	},

	userId: (state) => {
		return state.user.id
	},
}

const mutations = {
	SET_CHAT_USER(state, payload) {
		state.user = payload
	},

	// SET_USERS_LIST(state, user) {
	// 	let index = state.users.findIndex(x => x.id == user.id)

	// 	if (index >= 0) {
	// 		let userOb = {
	// 			id: parseInt(user.id),
	// 			count: parseInt(user.count),
	// 			isBlock: user.isBlock,
	// 			blockBy: user.blockBy,
	// 			lastMessage: user.lastMessage,
	// 			lastMsgTime: parseInt(user.lastMsgTime),
	// 			phoneNumber: state.users[index].phoneNumber,
	// 			name: state.users[index].name,
	// 			profileUrl: state.users[index].profileUrl,
	// 			email: state.users[index].email
	// 		}

	// 		state.users[index] = userOb
	// 	} else {
	// 		let userOb = {
	// 			id: parseInt(user.id),
	// 			count: parseInt(user.count),
	// 			isBlock: user.isBlock,
	// 			blockBy: user.blockBy,
	// 			lastMessage: user.lastMessage,
	// 			lastMsgTime: parseInt(user.lastMsgTime),
	// 			phoneNumber: '',
	// 			name: '',
	// 			profileUrl: '',
	// 			email: ''
	// 		}

	// 		state.users.push(userOb)
	// 	}

	// 	state.users = state.users.sort((a, b) => (a.lastMsgTime < b.lastMsgTime ? 1 : -1))
	// },

	UPDATE_USERS_PROFILE_INFO(state, user) {
		let index = state.users.findIndex((x) => x.id == user.id)
		if (index >= 0) {
			state.users[index].phoneNumber = user.phone
			state.users[index].name = user.first_name
			state.users[index].email = user.email
			state.users[index].profileUrl = [user.profile_images[0]]
		}
	},

	SET_ADMIN(state, user) {
		state.admin = user
	},

	RESET_USERS_LIST(state) {
		state.users = []
	},

	RESET_USER(state) {
		state.user = {}
	},

	USERS_SEARCH(state, payload) {
		state.users_search = payload
	},

	SET_UNREAD_MESSAGE_USER_COUNT(state, payload) {
		state.unreadMessageUserCount = payload
	},
}

const actions = {
	async getCount({ commit, rootState }) {
		await getcompany_id()

		let userCollection = await firebase
			.firestore()
			.collection(FIREBASE_COLLECTION_NAME_PREFIX + 'messages')
			.where('distributor_company_id', '==', rootState.auth.user_data.company_id)
			.where('is_active_chat', '==', true)
			.where('new_message_count', '>', 0)

		userCollection.onSnapshot((doc) => {
			let listDistCompanyId = []
			doc.forEach((doc) => {
				listDistCompanyId.push(doc.id)
			})
			commit('SET_UNREAD_MESSAGE_USER_COUNT', listDistCompanyId.length)
		})
	},

	async getUserList({ commit }) {
		try {
			let admin = firebase
				.firestore()
				.collection(FIREBASE_COLLECTION_NAME_PREFIX + 'users')
				.doc('0')

			admin.onSnapshot((doc) => {
				commit('SET_USERS_LIST', doc.data())
			})

			let usersListBaseOnRecentChat = firebase
				.firestore()
				.collection(FIREBASE_COLLECTION_NAME_PREFIX + 'users')
				.doc('0')
				.collection(FIREBASE_COLLECTION_NAME_PREFIX + 'recent_chats')
				.orderBy('lastMsgTime', 'desc')

			usersListBaseOnRecentChat.onSnapshot((doc) => {
				doc.forEach((doc) => {
					commit('SET_USERS_LIST', doc.data())
				})
			})

			let usersList = firebase
				.firestore()
				.collection(FIREBASE_COLLECTION_NAME_PREFIX + 'users')
				.orderBy('id', 'desc')

			usersList.onSnapshot((doc) => {
				doc.forEach((doc) => {
					commit('UPDATE_USERS_PROFILE_INFO', doc.data())
				})
			})
		} catch (error) {
			console.log(error)
		}
	},

	async getAdminUser({ commit }) {
		try {
			let admin = await firebase
				.firestore()
				.collection(FIREBASE_COLLECTION_NAME_PREFIX + 'users')
				.doc('0')
				.get()

			commit('SET_ADMIN', admin.data())
			return admin.data()
		} catch (error) {
			console.log(error)
		}
	},

	setChatUser({ commit }, payload) {
		commit('SET_CHAT_USER', payload)
	},

	async updateAdminChatWithID({ state }) {
		let id = ''
		if (state.user.id) {
			id = state.user.id
		}

		try {
			firebase
				.firestore()
				.collection(FIREBASE_COLLECTION_NAME_PREFIX + 'users')
				.doc('0')
				.update({
					chatting_with: id,
				})
		} catch (error) {
			console.log(error)
		}
	},

	async updateAdminFcmId({ dispatch }, fcm_id) {
		try {
			firebase
				.firestore()
				.collection(FIREBASE_COLLECTION_NAME_PREFIX + 'users')
				.doc('0')
				.update({
					fcm_id: [fcm_id],
					email: this.$auth.user.email,
					first_name: this.$auth.user.first_name,
					phone: this.$auth.user.phone,
				})
				.catch((err) => {
					console.log(err)
				})

			dispatch('getAdminUser')
		} catch (error) {
			console.log(error)
		}
	},

	usersSearch({ commit }, payload = null) {
		commit('USERS_SEARCH', payload)
	},
}

export default {
	state,
	getters,
	mutations,
	actions,
}
