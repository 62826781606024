import { getDashboardData } from '../../services/homeservice'
import { store } from '../store'

const state = {
	new_notifications: 0,
	is_new_list_filter: false,
	dashboardData: {
		dashboard: {},
		total_sales: {},
		delivery: {},
	},
	list_data: {
		list: {
			list_id: '',
			contractor_id: '',
			category_id: '',
			list_name: '',
			delivery_date: '',
			pickup: '',
			address: '',
			delivery_time: '',
			notes: '',
			status: '',
			created_at: '',
			idle_time: '',
			latitude: '',
			longitude: '',
			active_bids: 0,
			listitems: [],
			url: [],
			listDistCompanyId: '',
		},
		bid: {
			bid_id: '',
			list_id: '',
			date_of_delivery: '',
			sub_total: '',
			shipping: '',
			service_fee: '',
			sales_tax: '',
			tax_percentage: 0,
			total_amount: '',
			modification: '',
			reason_ids: [],
			comments: '',
			tag_status: '',
			status: '',
			created_at: '',
			time_of_delivery: '',
			latitude: '',
			longitude: '',
			start_time: '',
			end_time: '',
			biditems: [],
		},
		contractor: {
			fullname: '',
			phone: '',
		},
		notification: {
			notification_id: '',
			status: '',
			bid_id: '',
			list_id: '',
			created_at: '',
		},
	},

	cancel_list: {
		list_id: '',
	},

	delivered: {
		list_id: '',
		status: '',
	},

	list_bid_accepted: {
		list_id: '',
		status: '',
	},

	change_active_bids: {
		list_id: '',
		active_bids: '',
	},

	reset_list_counter: {
		list_id: '',
		idle_time: '',
	},

	bid_tag_status: {
		list_id: '',
		tag_status: '',
	},
}

const getters = {
	getList: (state) => {
		return state.list_data
	},

	getDashboardData: (state) => {
		return state.dashboardData
	},
}

const mutations = {
	listData: (state, data) => {
		let bid = data.bid
		let list = data.list
		let contractor = data.contractor
		let notification = data.notification

		state.list_data.listDistCompanyId = `${store.state.auth.user_data.company_id}DC` + `${list.list_id}L`

		state.list_data.list.list_id = list.list_id
		state.list_data.list.contractor_id = list.contractor_id
		state.list_data.list.category_id = list.category_id
		state.list_data.list.list_name = list.list_name
		state.list_data.list.delivery_date = list.delivery_date
		state.list_data.list.pickup = list.pickup
		state.list_data.list.address = list.address
		state.list_data.list.delivery_time = list.delivery_time
		state.list_data.list.notes = list.notes
		state.list_data.list.status = list.status
		state.list_data.list.created_at = list.created_at
		state.list_data.list.idle_time = list.idle_time
		state.list_data.list.latitude = list.latitude
		state.list_data.list.longitude = list.longitude
		state.list_data.list.listitems = list.listitems
		state.list_data.list.url = list.url

		if (!Object.keys(bid).length > 0) {
			state.list_data.bid.biditems = []
			list.listitems.forEach((item) => {
				let ob = {
					name: item.name,
					qty: item.qty,
					price: 0.0,
					notes: null,
					modification: 'N',
					show_note: item.show_note,
					url: item.url,
				}
				state.list_data.bid.biditems.push(ob)
			})

			list.listitems.forEach((item) => {
				let data = item.notes != null
				item['show_note'] = data
			})

			state.list_data.bid.biditems.forEach((item) => {
				let data = item.notes != null
				item['show_note'] = data
			})
		}

		if (Object.keys(bid).length > 0) {
			state.list_data.bid.bid_id = bid.bid_id
			state.list_data.bid.list_id = bid.list_id
			state.list_data.bid.date_of_delivery = bid.date_of_delivery
			state.list_data.bid.sub_total = bid.sub_total
			state.list_data.bid.shipping = bid.shipping
			state.list_data.bid.service_fee = bid.service_fee
			state.list_data.bid.sales_tax = bid.sales_tax
			state.list_data.bid.tax_percentage = bid.tax_percentage
			state.list_data.bid.total_amount = bid.total_amount
			state.list_data.bid.modification = bid.modification
			state.list_data.bid.reason_ids = bid.reason_ids
			state.list_data.bid.comments = bid.comments
			state.list_data.bid.tag_status = bid.tag_status
			state.list_data.bid.status = bid.status
			state.list_data.bid.created_at = bid.created_at
			state.list_data.bid.time_of_delivery = bid.time_of_delivery
			state.list_data.bid.latitude = bid.latitude
			state.list_data.bid.longitude = bid.longitude
			state.list_data.bid.start_time = bid.start_time
			state.list_data.bid.end_time = bid.end_time
			state.list_data.bid.biditems = bid.biditems

			state.list_data.bid.biditems.forEach((item) => {
				let data = item.notes != null
				item['show_note'] = data
			})
		}

		if (Object.keys(contractor).length > 0) {
			state.list_data.contractor.fullname = contractor.fullname
			state.list_data.contractor.phone = contractor.phone
		}

		if (Object.keys(notification).length > 0) {
			state.list_data.notification.notification_id = notification.notification_id
			state.list_data.notification.status = notification.status
			state.list_data.notification.bid_id = notification.bid_id
			state.list_data.notification.list_id = notification.list_id
			state.list_data.notification.created_at = notification.created_at

			if (notification.status === 'UR') {
				state.new_notifications = state.new_notifications + 1
			}
		}
	},

	listReset(state) {
		state.list_data.list.list_id = ''
		state.list_data.list.contractor_id = ''
		state.list_data.list.category_id = ''
		state.list_data.list.list_name = ''
		state.list_data.list.delivery_date = ''
		state.list_data.list.pickup = ''
		state.list_data.list.address = ''
		state.list_data.list.delivery_time = ''
		state.list_data.list.notes = ''
		state.list_data.list.status = ''
		state.list_data.list.created_at = ''
		state.list_data.list.idle_time = ''
		state.list_data.list.latitude = ''
		state.list_data.list.longitude = ''
		state.list_data.list.listitems = ''
		state.list_data.list.active_bids = 0

		state.list_data.bid.bid_id = ''
		state.list_data.bid.list_id = ''
		state.list_data.bid.date_of_delivery = ''
		state.list_data.bid.sub_total = 0
		state.list_data.bid.shipping = 0
		state.list_data.bid.service_fee = 0
		state.list_data.bid.sales_tax = 0
		state.list_data.bid.tax_percentage = 0
		state.list_data.bid.total_amount = 0
		state.list_data.bid.modification = ''
		state.list_data.bid.reason_ids = ''
		state.list_data.bid.comments = ''
		state.list_data.bid.tag_status = ''
		state.list_data.bid.status = ''
		state.list_data.bid.created_at = ''
		state.list_data.bid.time_of_delivery = ''
		state.list_data.bid.latitude = ''
		state.list_data.bid.longitude = ''
		state.list_data.bid.start_time = ''
		state.list_data.bid.end_time = ''
		state.list_data.bid.biditems = ''
		state.list_data.bid.url = ''

		state.list_data.contractor.fullname = ''
		state.list_data.contractor.phone = ''
	},

	cancelList(state, list_id) {
		state.cancel_list.list_id = list_id
	},

	delivered(state, data) {
		state.delivered.list_id = data.list_id
		state.delivered.status = data.status
	},

	listBidAccepted(state, data) {
		state.list_bid_accepted.list_id = data.list_id
		state.list_bid_accepted.status = data.status
	},

	changeActiveBids(state, data) {
		state.change_active_bids.list_id = data.list_id
		state.change_active_bids.active_bids = data.active_bids
	},

	resetListCounter(state, data) {
		state.reset_list_counter.list_id = data.list_id
		state.reset_list_counter.idle_time = data.idle_time
	},

	bidTagStatus(state, data) {
		state.bid_tag_status.list_id = data.list_id
		state.bid_tag_status.tag_status = data.tag_status
	},

	newNotification(state) {
		state.new_notifications = state.new_notifications + 1
	},

	readNotification(state) {
		let count_notification = state.new_notifications
		if (count_notification > 0) {
			state.new_notifications = state.new_notifications - 1
		}
	},

	GET_DASHBOARD_DATA(state, payload) {
		state.dashboardData.dashboard = payload['Total Transactions']
		state.dashboardData.total_sales = payload['Total Sales']['Total Sales']
		state.dashboardData.delivery = payload['Deliveries']
	},
}

const actions = {
	async getDashboardData({ commit }) {
		let data = await getDashboardData()
		if (data.data) {
			commit('GET_DASHBOARD_DATA', data.data)
		}
		return data.data
	},
}

export default {
	state,
	mutations,
	getters,
	actions,
}
